import { mainStore } from '@/shared/pinia-store/main';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { textColorChanged } from '@/shared/utils/textConverter';

export const useCharPageColors = () => {
  const defaultPageRoute = computed(() => {
    try {
      const router = useRouter();
      return router.currentRoute.value.name;
    } catch (error) {
      return '';
    }
  });

  const isTempAppliedCharProfile = computed(() => {
    const route = useRoute();
    return (
      ['character-profile-draft-new', 'character-profile-new'].includes(route.name as string) &&
      !isEmpty(get(customizeChar.value, 'template_applied'))
    );
  });

  const isCharacterProfile = computed(() => {
    const router = useRouter();
    return (
      router.currentRoute.value.name === 'character-profile-new' ||
      router.currentRoute.value.name === 'character-profile-draft-new'
    );
  });

  const { dark: isDark } = mainStore();

  const { customizeCharacter, currentTemplate } = characterStylizeStore();

  const customizeChar = computed(() => {
    return customizeCharacter.value;
  });

  const bg = computed(() => {
    if (
      ['character-profile-new', 'character-profile-draft-new', 'character-stylize', 'character-draft-stylize'].includes(
        defaultPageRoute.value as string
      )
    ) {
      return isEmpty(get(customizeChar.value, 'template_applied')) &&
        isDark.value &&
        ['character-profile-draft-new', 'character-profile-new'].includes(defaultPageRoute.value as string)
        ? '#010123 !important'
        : isEmpty(get(customizeChar.value, 'template_applied')) &&
          ['character-profile-draft-new', 'character-profile-new'].includes(defaultPageRoute.value as string)
        ? '#fff !important'
        : `${get(currentTemplate.value, 'data.theme.backgrounds.page')} !important`;
    }
    return 'rgba(0,0,0,0)';
  });

  const titleColor = computed(() => {
    const defaultClr = '#7050B7';
    const pageColor: any = bg.value;
    return isTempAppliedCharProfile.value ? `${textColorChanged(pageColor)}` : defaultClr;
  });

  const text = computed(() => {
    const defaultClr = isDark.value ? '#FFFFFF' : '#214163';
    return isEmpty(get(customizeCharacter.value, 'template_applied'))
      ? defaultClr
      : `${textColorChanged(bg.value)} !important`;
  });

  return { text, bg, isCharacterProfile, titleColor };
};
