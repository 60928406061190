<template>
  <div class="mobile-nav-bar d-flex align-items-center justify-content-between w-100">
    <div class="content h-100 w-100 d-flex justify-content-between align-items-center">
      <div v-if="isAuthenticated" class="start">
        <ProfileIconToggler v-if="isAuthenticated" with-side-menu />
      </div>
      <div
        v-if="isAuthenticated"
        class="center h-100 w-100 d-flex align-items-center justify-content-center flex-grow-1"
      >
        <ChLogo v-if="!isMobSmallScreen" />
      </div>
      <div class="w-100 d-flex align-items-left mr-n4" v-else>
        <ChLogo />
      </div>
      <div class="end d-flex justify-content-end" :class="{ minn: !isAuthenticated }">
        <AuthenticatedButtons v-if="isAuthenticated" />
        <NonAuthenticatedButtons v-else />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ProfileIconToggler from '../ProfileIconToggler.vue';
import AuthenticatedButtons from './AuthenticatedButtons.vue';
import ChLogo from './ChLogo.vue';
import NonAuthenticatedButtons from './NonAuthenticatedButtons.vue';
import { authStore } from '@/shared/pinia-store/auth';

const { isMobSmallScreen } = useWindowSize();
const { isAuthenticated } = authStore();
</script>

<style scoped lang="sass">
.marginer
  margin-left: 160px
.start
  width: 32px
.minn
  min-width: 200px
.content
  padding: 0 12px
.mobile-nav-bar
  height: calc(var(--default-nav-bar-height) + var(--safe-ios-margin))
  min-height: var(--default-nav-bar-height)
  flex-shrink: 0
  .content
    padding-top: var(--safe-ios-margin)
</style>
