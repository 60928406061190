<template>
  <div class="btns-wrap d-flex align-items-center position-relative">
    <div v-if="!isSearchPage" class="search mx-1">
      <div v-if="isSearching" class="d-flex w-100 search position-absolute align-items-center">
        <div
          style="width: 70px"
          class="inline-button clickable-search text-white d-flex align-items-center justify-content-center mr-1"
          @click="openSearch"
        >
          <i class="ti-angle-right" />
        </div>

        <SearchBar :clear-value="true" v-if="!isSearchPage" class="mr-1 search-bar-wrp" />
      </div>
      <div class="h-100" v-else @click="openSearch">
        <ion-button color="transparent" class="icon-button inline-button text-white fix-margin">
          <inline-svg height="15px" width="15px" src="/icons/search-icon.svg" />
        </ion-button>
      </div>
    </div>

    <div v-else class="place-holder" />
    <router-link to="/notifications" class="mx-1 position-relative d-block">
      <div v-if="unreadCount" class="ping">
        <div class="data">+{{ formatNumber(unreadCount) }}</div>
      </div>
      <ion-button color="transparent" class="icon-button inline-button text-white fix-margin">
        <inline-svg height="15px" width="15px" src="/icons/bell-icon.svg" />
      </ion-button>
    </router-link>

    <ion-button
      @click="goToRolechat"
      :class="{ disabledp: !userHasCharacters }"
      color="transparent"
      class="icon-button inline-button text-white ml-1"
    >
      <div>
        <i v-if="!userHasCharacters" class="ti-lock disabled-icon position-absolute" />
        <inline-svg height="20px" width="20px" src="/icons/pr-icon.svg" />
      </div>
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import SearchBar from './SearchBar.vue';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { formatNumber } from '@/shared/utils/number';
import store from '@/shared/store';

const isSearching = ref(false);
const worldWSConnection = ref<WebSocket | null>(null);
const unreadCount = computed(() => store.getters['NotificationsModule/unreadCount']);
const authToken = computed(() => authStore().authToken.value);

const { userHasCharacters } = authStore();

const openSearch = () => {
  isSearching.value = !isSearching.value;
};

const goToRolechat = () => {
  if (!userHasCharacters.value) {
    toast.show('Create character first to enable rolechats', 'nonative', 'secondary');
    return;
  }

  router.push({ name: 'rolechats' });
};

const router = useRouter();

const isSearchPage = computed(() => router.currentRoute.value.name === 'search');

const {
  public: { wsUrl },
} = useRuntimeConfig();
const { wsConnection } = useSocket(`${wsUrl}/ws/rolechats/user/?token=${authToken.value}`);

const hookRolechatUserWS = () => {
  if (wsConnection.value) {
    try {
      wsConnection.value.close();
    } catch (e) {
      // Handle if needed
    }
  }

  wsConnection.value.onmessage = (event) => {
    const data = JSON.parse(event.data).message;
    const router = useRouter();

    if (
      data.action === 'create' &&
      (router.currentRoute.value.name !== 'rolechat-room' ||
        router.currentRoute.value.params.id !== get(data.message, 'chat_room.id'))
    ) {
      toast.show(
        `New message received in ${get(data.message, 'chat_room.title', 'a rolechat')}`,
        'nonative',
        'primary'
      );
      store.dispatch('RolechatModule/updateUnreadRolechatStatus', true);
    }
  };
};

const hookWorldUserWS = () => {
  if (worldWSConnection.value) {
    try {
      worldWSConnection.value.close();
    } catch (e) {}
    worldWSConnection.value = null;
  }
  const {
    public: { wsUrl },
  } = useRuntimeConfig();
  const { wsConnection } = useSocket(`${wsUrl}/ws/worldchats/user/?token=${authToken.value}`);
  wsConnection.value.onmessage = (event) => {
    const data = JSON.parse(event.data).message;
    const router = useRouter();

    if (
      data.action === 'create' &&
      (router.currentRoute.value.name !== 'world-chat-room' ||
        router.currentRoute.value.params.id !== get(data.message, 'world_room.id'))
    ) {
      const senderName = get(data.message, 'character.info.name') || get(data.message, 'user.username', '');
      toast.show(
        `New message received in ${get(data.message, 'world_room.title', ' room')} room by ${senderName} in ${get(
          data.message,
          'world_room.world.name',
          ' world'
        )}  `,
        'nonative',
        'primary'
      );
    }
  };
};

onMounted(() => {
  hookRolechatUserWS();
  hookWorldUserWS();
});

onBeforeUnmount(() => {
  if (wsConnection.value) {
    try {
      wsConnection.value.close();
    } catch (e) {}
  }

  if (worldWSConnection.value) {
    try {
      worldWSConnection.value.close();
    } catch (e) {}
    worldWSConnection.value = null;
  }
});
</script>

<style lang="sass" scoped>
.btns-wrap
  height: 28px
.disabled-icon
  z-index: 1
  color: #FFF
  right: calc(50% - 8px)
  background: rgba(0,0,0,0.5)
  font-size: 8px
  padding: 1px
  top: -2px
  right: -2px
  border-radius: 20px
.disabledp
  opacity: 0.7
.place-holder
  width: 36px
  height: 28px
.search-bar-wrp
  width: 150px
  min-width: 150px
.search
  right: 146px
  top: 0
  height: 28px
  width: 28px
@keyframes ping
  0%
    transform: scale(1)
    opacity: 0.5

  100%
    transform: scale(2)
    opacity: 0
.ping
  position: absolute
  right: -5px
  top: -10px
  padding-left: 1em
  z-index: 11
  .data
    color: #000
    font-size: 7px
    font-weight: bold
    position: absolute
    top: 4.5px
    left: 0.5px
    z-index: 1
    width: 15px
    height: 15px
    display: flex
    justify-content: center
    align-items: center

.ping::before,
.ping::after
  background-color: #c5f531 !important
  content: ''
  position: absolute
  top: 0.25em
  left: 0
  width: 1em
  height: 1em
  border-radius: 50%
.ping::before
  animation: ping 1.7s ease infinite
  opacity: 0.25
.icon-size
  font-size: 20px
.inline-button
  background: rgba(255, 255, 255, 0.1)
  border-radius: 6px
  text-transform: unset !important
  color: #FFF
.clickable-search
  width: 24px !important
  min-width: 24px !important
  height: 24px !important
  cursor: pointer
  *
    font-size: 14px
.clickable-search:hover
  opacity: 0.7
.create-menu-icon
  margin-left: 5px
  width: 10px
  height: 10px
.ti-bell
  font-size: 18px
  position: relative
.icon-search
  position: relative
  font-size: 16px
  top: 1px
.chat-icon
  position: relative
  font-size: 1rem
  top: 1px
.icon-button
  position: relative
  width: 28px
  height: 28px
.fix-margin
  margin-top: -6px
</style>
