<template>
  <!-- <ion-menu-button @click="clickSideEffect" v-if="withSideMenu" mode="ios" class="menu-btn">
  
  </ion-menu-button> -->
  <ion-menu-toggle @click="clickSideEffect" v-if="withSideMenu" mode="ios" class="menu-btn" autoHide="false">
    <ProfileIcon :style="prfRingIconWidth" />
  </ion-menu-toggle>
  <div v-else @click="doAction"><ProfileIcon /></div>
</template>

<script lang="ts" setup>
import ProfileIcon from './ProfileIcon.vue';
import HomeProfilePopover from './nav-bar/popovers/home-profile-popover.vue';
import { popovers } from '@/shared/native';
import { onboardingStore } from '@/shared/pinia-store/onboarding';

const { width } = useWindowSize();
const { currentStep, stepForward } = onboardingStore();

const props = defineProps({
  withDropDown: {
    default: false,
    type: Boolean,
  },
  withSideMenu: {
    default: false,
    type: Boolean,
  },
});

const withSideMenu = computed(() => {
  return props.withSideMenu;
});

const prfRingIconWidth = computed(() => {
  return { width: '40px' };
});
const withDropDown = computed(() => {
  return props.withDropDown;
});

const dropdownToggle = (ev: MouseEvent) => {
  if (!withDropDown.value) return;

  popovers.open(ev, HomeProfilePopover);
};

const doAction = (ev: MouseEvent) => {
  dropdownToggle(ev);
};

const clickSideEffect = () => {
  // if (currentStep.value === 19) {
  //   stepForward();
  // }
};
</script>

<style lang="sass" scoped>
.menu-btn
  display: block
  --width: 32px
  --height: 32px
  --border-radius: 6px
  --padding-start: 0
  --padding-end: 0
</style>
